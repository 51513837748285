const Historys = [
  {
    id: 1,
    year: "2024",
    days: [
      {
        month: "03월",
        des: "제2 철도교통관제센터 AI, 빅데이터 개발 수주<br/><br/> 양산선 TNMS 시스템 개발 수주",
      },
      {
        month: "02월",
        des: "본점변경 : 서울(강남 봉은사로) -> 서울(강남 테헤란로)",
      },
    ],
  },
  {
    id: 2,
    year: "2023",
    days: [
      {
        month: "07월",
        des: "AI 기반 철도자산 관리 시스템 특허 등록<br/>(AI 기반 철도관제 관련 특허 3 건 특허 출원)",
      },
      {
        month: "03월",
        des: "추가 유상증자",
      },
    ],
  },
  {
    id: 3,
    year: "2022",
    days: [
      {
        month: "10월",
        des: "본점변경 : 서울(영등포) -> 서울(강남 봉은사로)",
      },
      {
        month: "05월",
        des: "분당 연구소 설립 (경기도 성남시 수정구)",
      },
      {
        month: "03월",
        des: "특허출원 : 인공 지능 기반의 메타 열차 중앙 관제 플랫폼 서비스를 제공하는 서버 및 그 방법 (10-2022-0026522)",
      },
    ],
  },
  {
    id: 4,
    year: "2021",
    days: [
      {
        month: "12월",
        des: "상호변경 : 디우스(주) -> (주)디메타 /<br/> 본점변경 : 경기 하남 -> 서울(영등포)",
      },
      { month: "09월", des: "투자 유치 (15억)" },
      {
        month: "04월",
        des: "특허출원 : 위조 차량 번호판 인식 방법 및 장치<br/> (10-2021-0044102)",
      },
    ],
  },
  {
    id: 5,
    year: "2020",
    days: [
      { month: "12월", des: "대중소상생형(삼성전자) 스마트공장 구축" },
      {
        month: "09월",
        des: "스마트팩토리 공급기업<br /><br />특허출원 : 차량 손상 관리 방법 및 차량 손상 관리 장치<br/> (10-2020-0111495)<br /><br />특허출원 : 모바일 디바이스 (10-2020-0111496)<br /><br />특허출원 : 차량 관리 보조 방법 및 차량 관리 보조장치<br/>(10-2020-0111493)<br /><br />특허출원 : 차량 관리 장치 (10-2020-0111494)",
      },
      {
        month: "03월",
        des: "IPS 스타트업밸류업2 호 개인투자조합 보통주 투자유치",
      },
      {
        month: "02월",
        des: "한국연구개발서비스협회 연구개발업 등록 중소기업 성과공유협약 체결",
      },
    ],
  },
  {
    id: 6,
    year: "2019",
    days: [
      {
        month: "12월",
        des: "한국산업단지공단 생산기술사업화지원사업,<br /><br />디우스(주) : “인공지능 기반 자동차 스크래치 검사 분야R&amp;D.”",
      },
      { month: "08월", des: "기업부설연구소 설립" },
      { month: "04월", des: "벤처기업 인증" },
      { month: "01월", des: "(주)디우스 설립" },
    ],
  },
];

export default Historys;
