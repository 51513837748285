import React, { Fragment } from "react";
import NavbarSubMenu from "../../components/NavbarSubMenu/NavbarSubMenu";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Testimonial from "../../components/Testimonial/Testimonial";
import Footer from "../../components/footer/Footer";
import HistorySection from "../../components/HistorySection/HistorySection";
import OrgSection from "../../components/OrgSection/OrgSection";

const AboutPage = () => {
  return (
    <Fragment>
      <NavbarSubMenu />
      <PageTitle pageTitle={"About Us"} pagesub={"About"} />
      <Testimonial />
      <HistorySection />
      <OrgSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
