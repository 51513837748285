import React from "react";
import HeaderTopbar from "../HeaderTopbar/HeaderTopbar";
import Logo from "../../images/logo-2.png";
import HeaderMenu from "../HeaderMenu/HeaderMenu";

const HeaderSubMenu = (props) => {
  return (
    <header id="header" className={`wpo-header-style-7 ${props.topbarNone}`}>
      <HeaderTopbar />
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <HeaderMenu Logo={Logo} />
        </nav>
      </div>
    </header>
  );
};

export default HeaderSubMenu;
