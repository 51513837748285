import React from "react";
import HeaderMenu from "../HeaderMenu/HeaderMenu";

const Header = (props) => {
  return (
    <header id="header" className={props.topbarNone}>
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <HeaderMenu Logo={props.Logo} />
        </nav>
      </div>
    </header>
  );
};

export default Header;
