import React from "react";
import HistoryCard from "../HistoryCard/HistoryCard";
import SectionTitle from "../SectionTitle/SectionTitle";
import Historys from "../../api/history";

const HistorySection = () => {
  return (
    <section
      className="wpo-section section-padding"
      style={{ paddingBottom: "0px" }}
    >
      <div className="container">
        <SectionTitle subTitle={"회사 연혁"} Title={"History"} />
        <div className="row">
          <div className="col col-xs-12">
            <div className="history-guide">
              {Historys.map((history, index) => (
                <HistoryCard key={index} data={history} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HistorySection;
