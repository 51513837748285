import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from "../../images/slider/background-station.jpg";
import hero2 from "../../images/slider/background-railway.jpg";

const settings = {
  dots: false,
  arrows: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  fade: true,
};

const Hero = () => {
  return (
    <section className="wpo-hero-slider">
      <div className="hero-container">
        <div className="hero-wrapper">
          <Slider {...settings}>
            <div className="hero-slide">
              <div
                className="slide-inner slide-bg-image"
                style={{ backgroundImage: `url(${hero1})` }}
              >
                <div className="container-fluid">
                  <div className="slide-content">
                    <div className="slide-title-sub">
                      <h5>Trusted Business Partner.</h5>
                    </div>
                    <div className="slide-title">
                      <h2>Deep learning based Disruptive Meta-AI technology</h2>
                    </div>
                    <div className="slide-text">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-slide">
              <div
                className="slide-inner slide-bg-image"
                style={{ backgroundImage: `url(${hero2})` }}
              >
                <div className="container-fluid">
                  <div className="slide-content">
                    <div className="slide-title-sub">
                      <h5>Trusted Business Partner.</h5>
                    </div>
                    <div className="slide-title">
                      <h2>
                        Disruptive technology provide transcending service with
                        AI
                      </h2>
                    </div>
                    <div className="slide-text">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Hero;
