import React from "react";

const ContactCard = (props) => (
  <div
    className="col col-xl-3 col-lg-6 col-md-6 col-12"
    style={{ display: "flex" }}
  >
    <div className="office-info-item" style={{ width: "100%" }}>
      <div className="office-info-icon">
        <div className="icon">
          <i className={`fi ${props.data.iconClass}`}></i>
        </div>
      </div>
      <div className="office-info-text">
        <h2 dangerouslySetInnerHTML={{ __html: props.data.title }}></h2>
        <p>{props.data.text1}</p>
        {props.data.text2 && <p>{props.data.text2}</p>}
      </div>
    </div>
  </div>
);

export default ContactCard;
