import React from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import { Link } from "react-router-dom";

const HeaderMenu = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
          <div className="mobail-menu">
            <MobileMenu />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-6">
          <div className="navbar-header">
            <Link onClick={ClickHandler} className="navbar-brand" to="/home">
              <img src={props.Logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-1 col-1">
          <div
            id="navbar"
            className="collapse navbar-collapse navigation-holder"
          >
            <button className="menu-close">
              <i className="ti-close"></i>
            </button>
            <ul className="nav navbar-nav mb-2 mb-lg-0">
              <li>
                <Link onClick={ClickHandler} to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <a
                  href="https://d-meta-career.notion.site/D-Meta-Career-3d87ea86a7b84808b14fd5613bebed1c"
                  about="career"
                >
                  Career
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-2 col-2">
          <div className="header-right">
            <Link onClick={ClickHandler} className="theme-btn" to="/contact">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
