import React from "react";

const HistoryCard = (props) => {
  return (
    <div className="history-inner">
      <ul>
        <li className="year">{props.data.year}</li>
        <li className="days">
          {props.data.days.map((day, index) => (
            <div className="item" key={index}>
              <span>{day.month}</span>
              <span dangerouslySetInnerHTML={{ __html: day.des }}></span>
            </div>
          ))}
        </li>
      </ul>
    </div>
  );
};

export default HistoryCard;
