import React from "react";
import Header from "../header/Header";
import LogoWhite from "../../images/logo.png";
import LogoColor from "../../images/logo-2.png";

export default function Navbar(props) {
  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const className = scroll > 80 ? "fixed-navbar active" : "fixed-navbar";
  const logo = scroll > 80 ? LogoColor : LogoWhite;

  return (
    <div className={className}>
      <Header hclass={props.hclass} Logo={logo} topbarNone={props.topbarNone} />
    </div>
  );
}
