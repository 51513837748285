const Contact = {
  infos: [
    {
      title: "Address<span style='font-size:1rem'>(Seoul)</span>",
      text1: "서울특별시 강남구 테헤란로 92길 39, 2층",
      iconClass: "flaticon-place",
    },
    {
      title: "Address<span style='font-size:1rem'>(Bundang)</span>",
      text1: "경기도 성남시 수정구 금토로 80번길 11, 이노베이션랩 912호",
      iconClass: "flaticon-place",
    },
    { title: "Email Us", text1: "info@d-meta.ai", iconClass: "flaticon-email" },
    {
      title: "Call Now",
      text1: "Tel. +82-2-2068-9250",
      text2: "Fax. +82-2-2068-9251",
      iconClass: "flaticon-phone-call",
    },
  ],
};

export default Contact;
