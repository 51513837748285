import React from "react";

const EmailTemplate = ({ subject, name, email, phone, message }) => (
  <html>
    <body>
      <h3>[{subject} 문의]</h3>
      <p>
        <strong>이름:</strong> {name}
      </p>
      <p>
        <strong>이메일:</strong> {email}
      </p>
      <p>
        <strong>휴대폰번호:</strong> {phone}
      </p>
      <p>
        <strong>문의내용:</strong> {message}
      </p>
    </body>
  </html>
);

export default EmailTemplate;
