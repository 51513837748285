import React from "react";
import simg from "../../images/mission-shape.png";

const MissionVission = (props) => {
  return (
    <section className="wpo-mission-vission-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-mission-item">
              <div className="wpo-mission-text">
                <h2>Our Mission</h2>
                <p>
                  Provides predictable, safe, and efficient services to
                  customers by smartening transportation and logistics starting
                  with railways based on technology beyond AI.
                </p>
                <h5 className="ko">
                  AI 기반 기술을 이용하여 철도를 시작으로 운송 및 물류 분야를
                  스마트화하여 고객에게 예측 가능하고, 안전하며, 효율적인
                  서비스를 제공할 것입니다.
                </h5>
              </div>
              <div className="shape-1"></div>
              <div className="shape-2">
                <img src={simg} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-vission-item">
              <div className="wpo-vission-text">
                <h2>Our Vission</h2>
                <h5 className="ko">
                  Meta-AI 기술은 철도를 포함한 운송 및 물류 분야에 혁신적인
                  변화를 가져올 것입니다.
                </h5>
                <p>
                  meta-AI technology based disruptive transformation of
                  transportation and logistics, including railways.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVission;
