import simg from "../images/service/technology-train-3.jpg";
import simg2 from "../images/service/technology-train-4.jpg";

const Services = [
  {
    Id: "1",
    sImg: simg,
    sTitle: "Railway Network Prediction and Optimization",
    description:
      "DRL based traffic scheduling for intelligent transportation system. Railway operation optimization via dynamic simulation and reinforcement learning. Railway simulation framework.",
    subTitle: "ARTEMIS",
    des2: "AI based Railway and Train Environment Management and Information Solution.",
    des3: "AI, Bigdata 기술을 기반으로 열차의 미래를 예측하여 사고를 미연에 방지하고 열차 스케쥴을 다양한 관점에서 최적화하여 보다 안전하고 효율적인 열차 관제를 지원하는 시스템.",
    icon: "flaticon-advisor",
  },
  {
    Id: "2",
    sImg: simg2,
    sTitle: "Abnormal Situation Recognition",
    description:
      "Object detection in railway and train station. 2D/3D pose estimation and action recognition. Digital twin representation.",
    subTitle: "ARTEVA",
    des2: "AI based Railway and Train Environment Video Analytics solution.",
    des3: "AI, Bigdata 기술을 기반으로 선로, 역사 등의 CCTV 비디오를 분석하여 이상행동탐지, 시설물 모니터링, 이상상황 탐지 등 안전 관제를 지원하는 시스템.",
    icon: "flaticon-team",
  },
  {
    Id: "3",
    sImg: simg,
    sTitle: "Railway Network Prediction and Optimization",
    description:
      "DRL based traffic scheduling for intelligent transportation system. Railway operation optimization via dynamic simulation and reinforcement learning. Railway simulation framework.",
    subTitle: "ARTEMIS",
    des2: "",
    des3: "",
    icon: "flaticon-taxes",
  },
  {
    Id: "4",
    sImg: simg2,
    sTitle: "Abnormal Situation Recognition",
    description:
      "Object detection in railway and train station. 2D/3D pose estimation and action recognition. Digital twin representation.",
    subTitle: "ARTEVA",
    des2: "",
    des3: "",
    icon: "flaticon-tax",
  },
];

export default Services;
