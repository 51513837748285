import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import orgImg from "../../images/team/org-chart1.png";

const OrgSection = () => {
  return (
    <section className="wpo-section section-padding">
      <div className="container">
        <SectionTitle subTitle={"조직도"} Title={"Organization Chart"} />
        <div className="row">
          <div className="col col-xs-12">
            <div style={{ textAlign: "center" }}>
              <img src={orgImg} width="1000" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrgSection;
