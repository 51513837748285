import { toast } from "react-toastify";
import Messages from "../api/message";

const apiUrl = process.env.REACT_APP_API_URL;

const getApiKeyAndCsrfToken = async () => {
  const response = await fetch(`${apiUrl}/api/generate-api-key`);
  if (!response.ok) {
    throw new Error(`${Messages.emailService.tokenError}`);
  }
  return response.json();
};

export const sendEmail = async (formData, customMessages) => {
  const apiKey = await getApiKeyAndCsrfToken();

  const msg = customMessages || Messages.emailService.toastMessages;
  const toastId = toast.loading(msg.pending);

  try {
    const response = await fetch(`${apiUrl}/api/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      toast.update(toastId, {
        render: msg.error,
        type: "error",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
      throw new Error(msg.error);
    }

    toast.update(toastId, {
      render: msg.success,
      type: "success",
      isLoading: false,
      autoClose: 2000,
      closeButton: true,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newApiKey = await getApiKeyAndCsrfToken();
      return sendEmail(formData, newApiKey);
    } else {
      toast.update(toastId, {
        render: error.message || msg.generalError,
        type: "error",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
      throw error;
    }
  }
};
