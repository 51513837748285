import React from "react";
import ContactForm from "../ContactFrom/ContactForm";
import Contact from "../../api/contact";
import ContactCard from "../ContactCard/ContactCard";

const Contactpage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row">
                {Contact.infos.map((contact, index) => (
                  <ContactCard key={index} data={contact} />
                ))}
              </div>
            </div>
            <div className="wpo-contact-title">
              <h2>Have Any Question?</h2>
              <p>문의는 언제나 환영입니다.</p>
            </div>
            <div className="wpo-contact-form-area">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <div className="map-cover">SEOUL</div>
          <iframe
            title="seoul"
            width="600"
            height="450"
            loading="lazy"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.077808144625!2d127.05974327624564!3d37.506082972054365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca43fce79b7db%3A0xeb36f71c64390a9!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDthYztl6TrnoDroZw5Muq4uCAzOQ!5e0!3m2!1sko!2skr!4v1709611512292!5m2!1sko!2skr"
          ></iframe>
        </div>
        <div className="wpo-contact-map">
          <div className="map-cover">BUNDANG</div>
          <iframe
            title="bundang"
            width="600"
            height="450"
            loading="lazy"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d792.3298898064941!2d127.08514928811859!3d37.40592106404219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b593983359e09%3A0xd799b5a70bc9fd60!2z7J2064W467Kg7J207IWY656p!5e0!3m2!1sko!2skr!4v1680499768024!5m2!1sko!2skr"
          ></iframe>
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
