import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from "../../images/testimonial/aboutUs.png";
import SectionTitle from "../SectionTitle/SectionTitle";

const testimonial = [
  {
    id: "01",
    tImg: ts1,
    Des: "<b>D-Meta.AI</b> 는 철도 신호 제어 부문의 국내 Top 코스닥 상장사로부터 투자를 받아, 안정적이면서도 성장 가능성이 무궁한 철도 분야를 시작으로 운송 및 물류 분야 시장의 강자를 목표로 합니다.",
    Des2: "<b>당사의 목표</b> 는 AI 기술로 최적의 시뮬레이션을 할 수 있는 철도 관제 시스템을 개발하고, 철도 시설물에 대한 다양한 검측 및 예측을 함으로써 철도 관제의 안전성과 최적성을 고도화 하고자 합니다.",
  },
];

const Testimonial = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  return (
    <section
      className="wpo-testimonial-section section-padding"
      style={{ paddingBottom: "0px" }}
    >
      <div className="container">
        <div className="wpo-testimonial-wrap">
          <div className="row">
            <div className="col-lg-6">
              <SectionTitle subTitle={"회사 개요"} Title={"About D-Meta.ai"} />
              <div className="wpo-testimonial-items">
                <Slider
                  asNavFor={nav2}
                  ref={(slider1) => setNav1(slider1)}
                  dots={false}
                  arrows={false}
                >
                  {testimonial.map((tesmnl, tsm) => (
                    <div className="wpo-testimonial-item" key={tsm}>
                      <div className="wpo-testimonial-text">
                        <p
                          dangerouslySetInnerHTML={{ __html: tesmnl.Des }}
                          style={{ fontSize: "16px" }}
                        ></p>
                        <p
                          dangerouslySetInnerHTML={{ __html: tesmnl.Des2 }}
                          style={{ fontSize: "16px", paddingTop: "10px" }}
                        ></p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="testimonial-right-side">
                <Slider
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  slidesToShow={1}
                  fade={false}
                  swipeToSlide={false}
                  focusOnSelect={false}
                >
                  {testimonial.map((tesmnl, tsm) => (
                    <div
                      className="testimonial-img"
                      key={tsm}
                      style={{ paddingTop: "10px" }}
                    >
                      <img src={tesmnl.tImg} alt="" />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
