import React from "react";
import abimg from "../../images/about/about-railway.jpg";

const About = (props) => {
  return (
    <section className="wpo-about-section-s4 section-padding pb-120">
      <div className="container">
        <div className="wpo-about-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <h4>Welcome To D-Meta.ai</h4>
                <h2>We are trusted consulting company.</h2>
                <p>
                  <b>D-Meta.ai</b>는 철도신호제어 부문의 국내 TOP 회사로부터
                  투자를 받아, 안정적이면서도 성장 가능성이 무궁한 철도분야를
                  시작으로 운송 및 물류분야 시장의 강자를 목표로 합니다.
                  <br />
                  <br />
                  <b>AI 기술력과 TOP 엔지니어</b>를 발판으로 모회사가 강자로
                  있는 철도신호제어 분야에서 AI, 빅데이터, 디지털 트윈이 반영된
                  새로운 시스템을 개발하고 있습니다.
                  <br />
                  <br />
                  <b>당사의 목표</b>는 AI기술로 최정의 시뮬레이션을 할 수 있는
                  철도관제 시스템을 개발하고, 철도 시설물에 대한 다양한 검측 및
                  예측을 함으로써 철도관제의 안전성과 최적성을 고도화하고자
                  합니다.
                  <br />
                  <br />
                  <b>궁극적으로는</b> 다양한 다른 교통수단과 물류 부문에
                  대해서도 적용할 수 있는 시스템으로 개발함으로써, 인류에게 보다
                  안전하고 최적화된 철도관제, 교통관제, 물류관제 구현에
                  이바지하고자 하며, 국내를 넘어 해외로도 진출하여 5년 이내 IPO
                  상장을 계획하고 있습니다.
                </p>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 col-12">
              <div className="wpo-about-wrap">
                <div className="wpo-about-img">
                  <div className="inner-img">
                    <img src={abimg} alt="about" height="575" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
