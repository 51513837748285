import React from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div className="App" id="scrool">
      <AllRoute />
      <ToastContainer
        limit={1}
        closeButton={true}
        autoClose={3000}
        hideProgressBar
      />
    </div>
  );
};

export default App;
