const Messages = {
  contactForm: {
    form: {
      namePlaceholder: "이름을 입력해 주세요.",
      emailPlaceholder: "이메일을 입력해 주세요.",
      phonePlaceholder: "휴대폰번호를 입력해 주세요.",
      messagePlaceholder: "문의사항을 입력해 주세요.",
      termsText: "개인정보 수집 및 이용에 동의합니다.",
      privacyPolicyLink: "(열기)",
      submitButton: "문의하기",
    },
    validationMessages: {
      required: "필수 입력 항목입니다.",
      email: "이메일이 정확한지 확인해 주세요.",
      phone: "휴대폰번호가 정확한지 확인해 주세요.",
      accepted: "약관에 동의해주세요.",
    },
    toastMessages: {
      pending: "문의사항 접수 중...",
      success: "문의 접수가 완료 되었습니다.",
      error: "문의 접수중 오류가 발생하였습니다.",
    },
    subjects: {
      default: "기타",
      recruitment: "인사/채용",
      business: "비즈니스",
      feedback: "피드백/제안",
    },
  },
  emailService: {
    toastMessages: {
      pending: "이메일 전송 중...",
      success: "이메일이 성공적으로 전송되었습니다.",
      error: "이메일 전송에 실패했습니다.",
      generalError: "이메일 전송 중 오류가 발생했습니다.",
    },
    tokenError: "API 키와 CSRF 토큰을 가져오는 데 실패했습니다.",
  },
};

export default Messages;
