import React, { Fragment } from "react";
import NavbarSubMenu from "../../components/NavbarSubMenu/NavbarSubMenu";
import PageTitle from "../../components/pagetitle/PageTitle";
import Error from "../../components/404/404";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";

const ErrorPage = () => {
  return (
    <Fragment>
      <NavbarSubMenu />
      <PageTitle pageTitle={"404"} pagesub={"404"} />
      <Error />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ErrorPage;
