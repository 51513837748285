import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import SimpleReactValidator from "simple-react-validator";
import { sendEmail } from "../../service/EmailService";
import { Link } from "react-router-dom";
import EmailTemplate from "../EmailTemplate/EmailTemplate";
import Messages from "../../api/message";

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, forceUpdate] = useState();
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: `${Messages.contactForm.subjects.default}`,
    phone: "",
    message: "",
    termsAccepted: false,
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
      messages: {
        required: Messages.contactForm.validationMessages.required,
        email: Messages.contactForm.validationMessages.email,
        phone: Messages.contactForm.validationMessages.phone,
        accepted: Messages.contactForm.validationMessages.accepted,
      },
    })
  );

  const changeHandler = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = name === "phone" ? value.replace(/[^0-9]/g, "") : value;

    setForms({
      ...forms,
      [name]: type === "checkbox" ? checked : newValue,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    if (validator.allValid()) {
      setIsSubmitting(true);
      validator.hideMessages();

      const html = ReactDOMServer.renderToString(
        <EmailTemplate
          subject={forms.subject}
          name={forms.name}
          email={forms.email}
          phone={forms.phone}
          message={forms.message}
        />
      );

      const apiData = {
        html,
      };

      sendEmail(apiData, Messages.contactForm.toastMessages)
        .then(() => {
          resetForm();
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          setIsSubmitting(false);
        });
    } else {
      validator.showMessages();
      forceUpdate((s) => !s);
    }
  };

  const resetForm = () => {
    setForms({
      name: "",
      email: "",
      subject: `${Messages.contactForm.subjects.default}`,
      phone: "",
      message: "",
      termsAccepted: false,
    });
  };

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder={Messages.contactForm.form.namePlaceholder}
            />
            {validator.message("name", forms.name, "required")}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder={Messages.contactForm.form.emailPlaceholder}
            />
            {validator.message("email", forms.email, "required|email")}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <input
              value={forms.phone}
              type="phone"
              name="phone"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder={Messages.contactForm.form.phonePlaceholder}
            />
            {validator.message("phone", forms.phone, "required|phone")}
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="form-field">
            <select
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              value={forms.subject}
              name="subject"
            >
              <option value={Messages.contactForm.subjects.default}>
                {Messages.contactForm.subjects.default}
              </option>
              <option value={Messages.contactForm.subjects.recruitment}>
                {Messages.contactForm.subjects.recruitment}
              </option>
              <option value={Messages.contactForm.subjects.business}>
                {Messages.contactForm.subjects.business}
              </option>
              <option value={Messages.contactForm.subjects.feedback}>
                {Messages.contactForm.subjects.feedback}
              </option>
            </select>
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <textarea
            onBlur={(e) => changeHandler(e)}
            onChange={(e) => changeHandler(e)}
            value={forms.message}
            type="text"
            name="message"
            placeholder={Messages.contactForm.form.messagePlaceholder}
          ></textarea>
          {validator.message("message", forms.message, "required")}
        </div>
      </div>
      <div className="agreement">
        <label>
          <input
            type="checkbox"
            name="termsAccepted"
            checked={forms.termsAccepted}
            onBlur={(e) => changeHandler(e)}
            onChange={(e) => changeHandler(e)}
          />
          <span>{Messages.contactForm.form.termsText}</span>
        </label>

        <Link
          className="privacy-policy-link"
          to={"/privacypolicy"}
          target="_blank"
          rel="privacy & policy"
          onClick={() => {
            window.scrollTo(10, 0);
          }}
        >
          {Messages.contactForm.form.privacyPolicyLink}
        </Link>
        {validator.message("termsAccepted", forms.termsAccepted, "accepted")}
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn">
          {Messages.contactForm.form.submitButton}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
